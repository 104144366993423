:root {
    --breakpoint-mobile: 320px;
    --breakpoint-portrait: 530px;
    --breakpoint-landscape: 800px;
    --breakpoint-notebook: 1180px;
    --breakpoint-desktop: 1580px;
    --breakpoint-desktop-large: 2240px;
    --breakpoint: var(--breakpoint-mobile);
    --document-min-width: 320px;
    --document-max-width: 2560px;
    --grid-columns: 6;
    --grid-gutter: 15px;
    --grid-spacing: 15px;
    --grid-margin: 0px;
    --grid-max-width: 100%;
    --grid-debug-color: #f06;
    --margin: 30px;
    --beat: 15px;
    --full-grid-column-width: calc((100% - (var(--grid-margin) * 2) - (var(--grid-gutter) * (var(--grid-columns) + 1))) / var(--grid-columns))
}

@media(min-width: 530px) {
    :root {
        --breakpoint: var(--breakpoint-portrait);
        --grid-columns: 12;
        --grid-gutter: 15px;
        --grid-spacing: 15px;
        --grid-margin: 15px;
        --grid-max-width: 100%;
        --grid-debug-color: #0cf;
        --margin: 30px
    }
}

@media(min-width: 800px) {
    :root {
        --breakpoint: var(--breakpoint-landscape);
        --grid-columns: 16;
        --grid-gutter: 15px;
        --grid-spacing: 15px;
        --grid-margin: 15px;
        --grid-max-width: 100%;
        --grid-debug-color: #0f9;
        --margin: 60px
    }
}

@media(min-width: 1180px) {
    :root {
        --breakpoint: var(--breakpoint-notebook);
        --grid-columns: 18;
        --grid-gutter: 15px;
        --grid-spacing: 15px;
        --grid-margin: 15px;
        --grid-max-width: 100%;
        --grid-debug-color: #c3f
    }
}

@media(min-width: 1580px) {
    :root {
        --breakpoint: var(--breakpoint-desktop);
        --grid-columns: 20;
        --grid-gutter: 15px;
        --grid-spacing: 15px;
        --grid-margin: 15px;
        --grid-max-width: 100%;
        --grid-debug-color: #f06
    }
}

@media(min-width: 2240px) {
    :root {
        --breakpoint: var(--breakpoint-desktop-large);
        --grid-columns: 24;
        --grid-gutter: 15px;
        --grid-spacing: 15px;
        --grid-margin: 15px;
        --grid-max-width: 100%;
        --grid-debug-color: #0cf
    }
}

:root {
    --color-accent: #533bff;
    --button-padding: 6px;
    --form-field-padding: 16px;
    --button-border: 4px;
    --form-field-border: 4px;
    --button-small-padding: 6px
}

@media(min-width: 800px) {
    :root {
        --button-padding: 12px;
        --form-field-padding: 21px
    }
}

:root {
    --back-link-font-size: 14px;
    --back-link-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --back-link-font-style: normal;
    --back-link-font-variant: normal;
    --back-link-font-weight: 400;
    --back-link-letter-spacing: 0;
    --back-link-line-height: 14px;
    --back-link-margin-bottom: 15px;
    --back-link-margin-top: 15px;
    --back-link-padding-bottom: 8px;
    --back-link-padding-top: 8px;
    --back-link-text-transform: normal;
    --back-link-word-spacing: normal;
}

:root {
    --button-font-size: 16px;
    --button-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --button-font-style: normal;
    --button-font-variant: normal;
    --button-font-weight: 400;
    --button-letter-spacing: normal;
    --button-line-height: 25px;
    --button-margin-bottom: 0;
    --button-margin-top: 0px;
    --button-padding-bottom: 8px;
    --button-padding-top: 8px;
    --button-text-transform: none;
    --button-word-spacing: normal;
}

:root {
    --button--primary-font-size: 16px;
    --button--primary-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --button--primary-font-style: normal;
    --button--primary-font-variant: normal;
    --button--primary-font-weight: 900;
    --button--primary-letter-spacing: 0;
    --button--primary-line-height: 16px;
    --button--primary-margin-bottom: 0;
    --button--primary-margin-top: 1px;
    --button--primary-padding-bottom: 22px;
    --button--primary-padding-top: 22px;
    --button--primary-text-transform: uppercase;
    --button--primary-word-spacing: normal;
}

:root {
    --button--secondary-font-size: 16px;
    --button--secondary-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --button--secondary-font-style: normal;
    --button--secondary-font-variant: normal;
    --button--secondary-font-weight: 900;
    --button--secondary-letter-spacing: 0;
    --button--secondary-line-height: 16px;
    --button--secondary-margin-bottom: 0;
    --button--secondary-margin-top: 0;
    --button--secondary-padding-bottom: 13px;
    --button--secondary-padding-top: 12px;
    --button--secondary-text-transform: normal;
    --button--secondary-word-spacing: normal;
}

:root {
    --button--tertiary-font-size: 14px;
    --button--tertiary-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --button--tertiary-font-style: normal;
    --button--tertiary-font-variant: normal;
    --button--tertiary-font-weight: 700;
    --button--tertiary-letter-spacing: 0;
    --button--tertiary-line-height: 14px;
    --button--tertiary-margin-bottom: 0;
    --button--tertiary-margin-top: 0;
    --button--tertiary-padding-bottom: 14px;
    --button--tertiary-padding-top: 13px;
    --button--tertiary-text-transform: uppercase;
    --button--tertiary-word-spacing: normal;
}

:root {
    --campaign-spread-introduction-font-size: 20px;
    --campaign-spread-introduction-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --campaign-spread-introduction-font-style: normal;
    --campaign-spread-introduction-font-variant: normal;
    --campaign-spread-introduction-font-weight: normal;
    --campaign-spread-introduction-letter-spacing: 0;
    --campaign-spread-introduction-line-height: 30px;
    --campaign-spread-introduction-margin-bottom: 30px;
    --campaign-spread-introduction-margin-top: 30px;
    --campaign-spread-introduction-padding-bottom: 0;
    --campaign-spread-introduction-padding-top: 0;
    --campaign-spread-introduction-text-transform: normal;
    --campaign-spread-introduction-word-spacing: normal;
}

@media(min-width: 800px) {
    :root {
        --campaign-spread-introduction-font-size: 22px
    }
}

:root {
    --campaign-spread-medium-highlight-title-font-size: 18px;
    --campaign-spread-medium-highlight-title-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --campaign-spread-medium-highlight-title-font-style: normal;
    --campaign-spread-medium-highlight-title-font-variant: normal;
    --campaign-spread-medium-highlight-title-font-weight: 900;
    --campaign-spread-medium-highlight-title-letter-spacing: 0;
    --campaign-spread-medium-highlight-title-line-height: 20px;
    --campaign-spread-medium-highlight-title-margin-bottom: 15px;
    --campaign-spread-medium-highlight-title-margin-top: 60px;
    --campaign-spread-medium-highlight-title-padding-bottom: 5px;
    --campaign-spread-medium-highlight-title-padding-top: 5px;
    --campaign-spread-medium-highlight-title-text-transform: uppercase;
    --campaign-spread-medium-highlight-title-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --campaign-spread-medium-highlight-title-font-size: 28px;
        --campaign-spread-medium-highlight-title-line-height: 32px;
        --campaign-spread-medium-highlight-title-padding-bottom: 6px;
        --campaign-spread-medium-highlight-title-padding-top: 7px
    }
}

:root {
    --caption-font-size: 12px;
    --caption-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --caption-font-style: normal;
    --caption-font-variant: normal;
    --caption-font-weight: 400;
    --caption-letter-spacing: 0;
    --caption-line-height: 12px;
    --caption-margin-bottom: 15px;
    --caption-margin-top: 15px;
    --caption-padding-bottom: 1px;
    --caption-padding-top: 2px;
    --caption-text-transform: none;
    --caption-word-spacing: normal;
}

:root {
    --card-caption-font-size: 10px;
    --card-caption-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --card-caption-font-style: normal;
    --card-caption-font-variant: normal;
    --card-caption-font-weight: 400;
    --card-caption-letter-spacing: 0;
    --card-caption-line-height: 25px;
    --card-caption-margin-bottom: 15px;
    --card-caption-margin-top: 15px;
    --card-caption-padding-bottom: 2px;
    --card-caption-padding-top: 3px;
    --card-caption-text-transform: normal;
    --card-caption-word-spacing: normal;
}

:root {
    --card-text-font-size: 14px;
    --card-text-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --card-text-font-style: normal;
    --card-text-font-variant: normal;
    --card-text-font-weight: 400;
    --card-text-letter-spacing: 0;
    --card-text-line-height: 20px;
    --card-text-margin-bottom: 15px;
    --card-text-margin-top: 15px;
    --card-text-padding-bottom: 5px;
    --card-text-padding-top: 5px;
    --card-text-text-transform: normal;
    --card-text-word-spacing: normal;
}

:root {
    --card-title-font-size: 16px;
    --card-title-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --card-title-font-style: normal;
    --card-title-font-variant: normal;
    --card-title-font-weight: 900;
    --card-title-letter-spacing: 0;
    --card-title-line-height: 18px;
    --card-title-margin-bottom: 30px;
    --card-title-margin-top: 60px;
    --card-title-padding-bottom: 6px;
    --card-title-padding-top: 6px;
    --card-title-text-transform: normal;
    --card-title-word-spacing: normal;
}

:root {
    --content-highlight-title-font-size: 30px;
    --content-highlight-title-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --content-highlight-title-font-style: normal;
    --content-highlight-title-font-variant: normal;
    --content-highlight-title-font-weight: 900;
    --content-highlight-title-letter-spacing: 0;
    --content-highlight-title-line-height: 32px;
    --content-highlight-title-margin-bottom: 15px;
    --content-highlight-title-margin-top: 60px;
    --content-highlight-title-padding-bottom: 6px;
    --content-highlight-title-padding-top: 7px;
    --content-highlight-title-text-transform: uppercase;
    --content-highlight-title-word-spacing: normal
}

@media(min-width: 1580px) {
    :root {
        --content-highlight-title-font-size: 42px;
        --content-highlight-title-line-height: 45px;
        --content-highlight-title-padding-bottom: 0;
        --content-highlight-title-padding-top: 0
    }
}

:root {
    --featured-campaign-title-font-size: 30px;
    --featured-campaign-title-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --featured-campaign-title-font-style: normal;
    --featured-campaign-title-font-variant: normal;
    --featured-campaign-title-font-weight: 900;
    --featured-campaign-title-letter-spacing: 0;
    --featured-campaign-title-line-height: 32px;
    --featured-campaign-title-margin-bottom: 15px;
    --featured-campaign-title-margin-top: 60px;
    --featured-campaign-title-padding-bottom: 6px;
    --featured-campaign-title-padding-top: 7px;
    --featured-campaign-title-text-transform: uppercase;
    --featured-campaign-title-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --featured-campaign-title-font-size: 42px;
        --featured-campaign-title-line-height: 45px;
        --featured-campaign-title-padding-bottom: 0;
        --featured-campaign-title-padding-top: 0
    }
}

@media screen and (min-width: 1280px) {
    :root {
        --featured-campaign-title-font-size: 60px;
        --featured-campaign-title-letter-spacing: -1px;
        --featured-campaign-title-line-height: 64px;
        --featured-campaign-title-padding-bottom: 0;
        --featured-campaign-title-padding-top: 1px
    }
}

:root {
    --field-label-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --field-label-font-size: 10px;
    --field-label-font-style: normal;
    --field-label-font-variant: normal;
    --field-label-font-weight: 700;
    --field-label-letter-spacing: 0;
    --field-label-line-height: 8px;
    --field-label-margin-bottom: 15px;
    --field-label-margin-top: 0;
    --field-label-padding-bottom: 6px;
    --field-label-padding-top: 1px;
    --field-label-text-transform: lowercase;
    --field-label-word-spacing: normal;
}

:root {
    --footer-heading-font-size: 14px;
    --footer-heading-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --footer-heading-font-style: normal;
    --footer-heading-font-variant: normal;
    --footer-heading-font-weight: 900;
    --footer-heading-letter-spacing: 0;
    --footer-heading-line-height: 16px;
    --footer-heading-margin-bottom: -25px;
    --footer-heading-margin-top: 60px;
    --footer-heading-padding-bottom: 7px;
    --footer-heading-padding-top: 7px;
    --footer-heading-text-transform: uppercase;
    --footer-heading-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --footer-heading-font-size: 16px;
        --footer-heading-line-height: 18px;
        --footer-heading-padding-bottom: 6px;
        --footer-heading-padding-top: 6px
    }
}

:root {
    --form-field-font-size: 16px;
    --form-field-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --form-field-font-style: normal;
    --form-field-font-variant: normal;
    --form-field-font-weight: 400;
    --form-field-letter-spacing: -.2px;
    --form-field-line-height: 20px;
    --form-field-margin-bottom: 0;
    --form-field-margin-top: 0;
    --form-field-padding-bottom: 0;
    --form-field-padding-top: 0;
    --form-field-text-transform: normal;
    --form-field-word-spacing: normal;
}

:root {
    --header-author-link-font-size: 16px;
    --header-author-link-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --header-author-link-font-style: normal;
    --header-author-link-font-variant: normal;
    --header-author-link-font-weight: 400;
    --header-author-link-letter-spacing: 0;
    --header-author-link-line-height: 30px;
    --header-author-link-margin-bottom: 0;
    --header-author-link-margin-top: 15px;
    --header-author-link-padding-bottom: 0;
    --header-author-link-padding-top: 0;
    --header-author-link-text-transform: normal;
    --header-author-link-word-spacing: normal;
}

:root {
    --header-author-font-size: 16px;
    --header-author-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --header-author-font-style: normal;
    --header-author-font-variant: normal;
    --header-author-font-weight: 400;
    --header-author-letter-spacing: 0;
    --header-author-line-height: 20px;
    --header-author-margin-bottom: 0;
    --header-author-margin-top: 15px;
    --header-author-padding-bottom: 0;
    --header-author-padding-top: 0;
    --header-author-text-transform: normal;
    --header-author-word-spacing: normal;
}

:root {
    --header-title-font-size: 32px;
    --header-title-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --header-title-font-style: normal;
    --header-title-font-variant: normal;
    --header-title-font-weight: 900;
    --header-title-letter-spacing: 0;
    --header-title-line-height: 34px;
    --header-title-margin-bottom: 15px;
    --header-title-margin-top: 60px;
    --header-title-padding-bottom: 5px;
    --header-title-padding-top: 6px;
    --header-title-text-transform: uppercase;
    --header-title-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --header-title-font-size: 43px;
        --header-title-line-height: 45px;
        --header-title-padding-bottom: 0;
        --header-title-padding-top: 0
    }
}

:root {
    --heading-1-font-size: 42px;
    --heading-1-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --heading-1-font-style: normal;
    --heading-1-font-variant: normal;
    --heading-1-font-weight: 900;
    --heading-1-letter-spacing: 0;
    --heading-1-line-height: 45px;
    --heading-1-margin-bottom: 15px;
    --heading-1-margin-top: 60px;
    --heading-1-padding-bottom: 0;
    --heading-1-padding-top: 0;
    --heading-1-text-transform: uppercase;
    --heading-1-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --heading-1-font-size: 60px;
        --heading-1-letter-spacing: -1px;
        --heading-1-line-height: 64px;
        --heading-1-padding-bottom: 0;
        --heading-1-padding-top: 1px
    }
}

:root {
    --heading-2-font-size: 26px;
    --heading-2-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --heading-2-font-style: normal;
    --heading-2-font-variant: normal;
    --heading-2-font-weight: 900;
    --heading-2-letter-spacing: 0;
    --heading-2-line-height: 28px;
    --heading-2-margin-bottom: 15px;
    --heading-2-margin-top: 60px;
    --heading-2-padding-bottom: 8px;
    --heading-2-padding-top: 9px;
    --heading-2-text-transform: uppercase;
    --heading-2-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --heading-2-font-size: 36px;
        --heading-2-line-height: 40px;
        --heading-2-padding-bottom: 2px;
        --heading-2-padding-top: 3px
    }
}

:root {
    --heading-3-font-size: 18px;
    --heading-3-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --heading-3-font-style: normal;
    --heading-3-font-variant: normal;
    --heading-3-font-weight: 900;
    --heading-3-letter-spacing: 0;
    --heading-3-line-height: 20px;
    --heading-3-margin-bottom: -15px;
    --heading-3-margin-top: 60px;
    --heading-3-padding-bottom: 5px;
    --heading-3-padding-top: 5px;
    --heading-3-text-transform: uppercase;
    --heading-3-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --heading-3-font-size: 30px;
        --heading-3-line-height: 32px;
        --heading-3-padding-bottom: 6px;
        --heading-3-padding-top: 7px
    }
}

:root {
    --heading-4-font-size: 16px;
    --heading-4-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --heading-4-font-style: normal;
    --heading-4-font-variant: normal;
    --heading-4-font-weight: 900;
    --heading-4-letter-spacing: 0;
    --heading-4-line-height: 18px;
    --heading-4-margin-bottom: -20px;
    --heading-4-margin-top: 60px;
    --heading-4-padding-bottom: 6px;
    --heading-4-padding-top: 6px;
    --heading-4-text-transform: uppercase;
    --heading-4-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --heading-4-font-size: 18px;
        --heading-4-line-height: 20px;
        --heading-4-padding-bottom: 5px;
        --heading-4-padding-top: 5px
    }
}

:root {
    --heading-5-font-size: 14px;
    --heading-5-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --heading-5-font-style: normal;
    --heading-5-font-variant: normal;
    --heading-5-font-weight: 900;
    --heading-5-letter-spacing: 0;
    --heading-5-line-height: 16px;
    --heading-5-margin-bottom: -25px;
    --heading-5-margin-top: 60px;
    --heading-5-padding-bottom: 7px;
    --heading-5-padding-top: 7px;
    --heading-5-text-transform: normal;
    --heading-5-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --heading-5-font-size: 16px;
        --heading-5-line-height: 18px;
        --heading-5-padding-bottom: 6px;
        --heading-5-padding-top: 6px
    }
}

:root {
    --heading-6-font-size: 12px;
    --heading-6-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --heading-6-font-style: normal;
    --heading-6-font-variant: normal;
    --heading-6-font-weight: 900;
    --heading-6-letter-spacing: 0;
    --heading-6-line-height: 14px;
    --heading-6-margin-bottom: -30px;
    --heading-6-margin-top: 60px;
    --heading-6-padding-bottom: 0;
    --heading-6-padding-top: 1px;
    --heading-6-text-transform: normal;
    --heading-6-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --heading-6-font-size: 14px;
        --heading-6-line-height: 16px;
        --heading-6-padding-bottom: 7px;
        --heading-6-padding-top: 7px
    }
}

:root {
    --hero-tryptich-heading-2-font-size: 30px;
    --hero-tryptich-heading-2-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --hero-tryptich-heading-2-font-style: normal;
    --hero-tryptich-heading-2-font-variant: normal;
    --hero-tryptich-heading-2-font-weight: 900;
    --hero-tryptich-heading-2-letter-spacing: 0;
    --hero-tryptich-heading-2-line-height: 32px;
    --hero-tryptich-heading-2-margin-bottom: 15px;
    --hero-tryptich-heading-2-margin-top: 60px;
    --hero-tryptich-heading-2-padding-bottom: 6px;
    --hero-tryptich-heading-2-padding-top: 7px;
    --hero-tryptich-heading-2-text-transform: uppercase;
    --hero-tryptich-heading-2-word-spacing: normal
}

@media(min-width: 1180px) {
    :root {
        --hero-tryptich-heading-2-font-size: 42px;
        --hero-tryptich-heading-2-line-height: 45px;
        --hero-tryptich-heading-2-padding-bottom: 0;
        --hero-tryptich-heading-2-padding-top: 0
    }
}

:root {
    --hero-tryptich-heading-3-font-size: 18px;
    --hero-tryptich-heading-3-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --hero-tryptich-heading-3-font-style: normal;
    --hero-tryptich-heading-3-font-variant: normal;
    --hero-tryptich-heading-3-font-weight: 900;
    --hero-tryptich-heading-3-letter-spacing: 0;
    --hero-tryptich-heading-3-line-height: 20px;
    --hero-tryptich-heading-3-margin-bottom: -15px;
    --hero-tryptich-heading-3-margin-top: 60px;
    --hero-tryptich-heading-3-padding-bottom: 5px;
    --hero-tryptich-heading-3-padding-top: 5px;
    --hero-tryptich-heading-3-text-transform: uppercase;
    --hero-tryptich-heading-3-word-spacing: normal
}

@media(min-width: 1180px) {
    :root {
        --hero-tryptich-heading-3-font-size: 30px;
        --hero-tryptich-heading-3-line-height: 32px;
        --hero-tryptich-heading-3-padding-bottom: 6px;
        --hero-tryptich-heading-3-padding-top: 7px
    }
}

:root {
    --introduction-font-size: 20px;
    --introduction-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --introduction-font-style: normal;
    --introduction-font-variant: normal;
    --introduction-font-weight: 700;
    --introduction-letter-spacing: 0;
    --introduction-line-height: 30px;
    --introduction-margin-bottom: 30px;
    --introduction-margin-top: 30px;
    --introduction-padding-bottom: 0;
    --introduction-padding-top: 0;
    --introduction-text-transform: normal;
    --introduction-word-spacing: normal;
}

@media(min-width: 800px) {
    :root {
        --introduction-font-size: 22px
    }
}

:root {
    --menu-bar-department-title-font-size: 12px;
    --menu-bar-department-title-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --menu-bar-department-title-font-style: normal;
    --menu-bar-department-title-font-variant: normal;
    --menu-bar-department-title-font-weight: 900;
    --menu-bar-department-title-letter-spacing: 0;
    --menu-bar-department-title-line-height: 15px;
    --menu-bar-department-title-margin-bottom: -25px;
    --menu-bar-department-title-margin-top: 15px;
    --menu-bar-department-title-padding-bottom: 7.5px;
    --menu-bar-department-title-padding-top: 7.5px;
    --menu-bar-department-title-text-transform: uppercase;
    --menu-bar-department-title-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --menu-bar-department-title-font-size: 16px;
        --menu-bar-department-title-line-height: 18px;
        --menu-bar-department-title-padding-bottom: 6px;
        --menu-bar-department-title-padding-top: 6px
    }
}

:root {
    --paragraph-font-size: 16px;
    --paragraph-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --paragraph-font-style: normal;
    --paragraph-font-variant: normal;
    --paragraph-font-weight: 400;
    --paragraph-letter-spacing: 0;
    --paragraph-line-height: 25px;
    --paragraph-margin-bottom: 30px;
    --paragraph-margin-top: 30px;
    --paragraph-padding-bottom: 2px;
    --paragraph-padding-top: 3px;
    --paragraph-text-transform: normal;
    --paragraph-word-spacing: normal;
}

@media(min-width: 800px) {
    :root {
        --paragraph-font-size: 18px
    }
}

:root {
    --quote-font-size: 30px;
    --quote-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --quote-font-style: normal;
    --quote-font-variant: normal;
    --quote-font-weight: 900;
    --quote-letter-spacing: 0;
    --quote-line-height: 32px;
    --quote-margin-bottom: 15px;
    --quote-margin-top: 60px;
    --quote-padding-bottom: 6px;
    --quote-padding-top: 7px;
    --quote-text-transform: normal;
    --quote-word-spacing: normal
}

@media(min-width: 800px) {
    :root {
        --quote-font-size: 42px;
        --quote-line-height: 45px;
        --quote-padding-bottom: 0;
        --quote-padding-top: 0
    }
}

:root {
    --tag-link-large-font-size: 18px;
    --tag-link-large-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --tag-link-large-font-style: normal;
    --tag-link-large-font-variant: normal;
    --tag-link-large-font-weight: 400;
    --tag-link-large-letter-spacing: 0;
    --tag-link-large-line-height: 20px;
    --tag-link-large-margin-bottom: 15px;
    --tag-link-large-margin-top: 15px;
    --tag-link-large-padding-bottom: 14px;
    --tag-link-large-padding-top: 14px;
    --tag-link-large-text-transform: normal;
    --tag-link-large-word-spacing: normal;
}

:root {
    --tag-link-font-size: 14px;
    --tag-link-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --tag-link-font-style: normal;
    --tag-link-font-variant: normal;
    --tag-link-font-weight: 400;
    --tag-link-letter-spacing: 0;
    --tag-link-line-height: 14px;
    --tag-link-margin-bottom: 15px;
    --tag-link-margin-top: 15px;
    --tag-link-padding-bottom: 8px;
    --tag-link-padding-top: 8px;
    --tag-link-text-transform: normal;
    --tag-link-word-spacing: normal;
}

:root {
    --tag-font-size: 12px;
    --tag-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --tag-font-style: normal;
    --tag-font-variant: normal;
    --tag-font-weight: 400;
    --tag-letter-spacing: 0;
    --tag-line-height: 10px;
    --tag-margin-bottom: 15px;
    --tag-margin-top: 15px;
    --tag-padding-bottom: 2px;
    --tag-padding-top: 3px;
    --tag-text-transform: uppercase;
    --tag-word-spacing: normal;
}

:root {
    --theme-card-caption-font-size: 12px;
    --theme-card-caption-font-stack: Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --theme-card-caption-font-style: normal;
    --theme-card-caption-font-variant: normal;
    --theme-card-caption-font-weight: 700;
    --theme-card-caption-letter-spacing: 0;
    --theme-card-caption-line-height: 25px;
    --theme-card-caption-margin-bottom: 15px;
    --theme-card-caption-margin-top: 15px;
    --theme-card-caption-padding-bottom: 2px;
    --theme-card-caption-padding-top: 3px;
    --theme-card-caption-text-transform: normal;
    --theme-card-caption-word-spacing: normal;
}

:root {
    --theme-card-carousel-header-font-size: 40px;
    --theme-card-carousel-header-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --theme-card-carousel-header-font-style: normal;
    --theme-card-carousel-header-font-variant: normal;
    --theme-card-carousel-header-font-weight: 900;
    --theme-card-carousel-header-letter-spacing: -1px;
    --theme-card-carousel-header-line-height: 1em;
    --theme-card-carousel-header-margin-bottom: 0;
    --theme-card-carousel-header-margin-top: 0;
    --theme-card-carousel-header-padding-bottom: 0;
    --theme-card-carousel-header-padding-top: 0;
    --theme-card-carousel-header-text-transform: uppercase;
    --theme-card-carousel-header-word-spacing: normal
}

@media(min-width: 530px) {
    :root {
        --theme-card-carousel-header-font-size: 60px;
        --theme-card-carousel-header-letter-spacing: -1px;
        --theme-card-carousel-header-line-height: 1em
    }
}

@media(min-width: 800px) {
    :root {
        --theme-card-carousel-header-font-size: 90px;
        --theme-card-carousel-header-letter-spacing: -2px;
        --theme-card-carousel-header-line-height: 1em
    }
}

@media(min-width: 1180px) {
    :root {
        --theme-card-carousel-header-font-size: 120px;
        --theme-card-carousel-header-letter-spacing: -3px;
        --theme-card-carousel-header-line-height: 1em
    }
}

@media(min-width: 1580px) {
    :root {
        --theme-card-carousel-header-font-size: 168px;
        --theme-card-carousel-header-letter-spacing: -4px;
        --theme-card-carousel-header-line-height: 1em
    }
}

:root {
    --theme-card-label-font-size: 12px;
    --theme-card-label-font-stack: Helvetica Neue LT Std Webfont, Suisse Webfont, Helvetica Neue, Helvetica, Arial, sans-serif;
    --theme-card-label-font-style: normal;
    --theme-card-label-font-variant: normal;
    --theme-card-label-font-weight: 700;
    --theme-card-label-letter-spacing: 0;
    --theme-card-label-line-height: 10px;
    --theme-card-label-margin-bottom: 15px;
    --theme-card-label-margin-top: 15px;
    --theme-card-label-padding-bottom: 2px;
    --theme-card-label-padding-top: 3px;
    --theme-card-label-text-transform: uppercase;
    --theme-card-label-word-spacing: normal;
}

:root {
    --layout-campaign-spread-content: column 1 / span 6;
    --layout-campaign-spread-image: column 1 / span 6;
    --layout-campaign-spread-medium-content: column 1 / span 5;
    --layout-campaign-spread-medium-image: column 1 / span 6;
    --layout-card-carousel: column 1 / span 5;
    --layout-card-grid: column 1 / span 6;
    --layout-card-list: column 1 / span 6;
    --layout-content-aside-content--default: column 1 / span 6;
    --layout-content-aside-content--reversed: column 1 / span 6;
    --layout-content-aside-image--default: column 1 / span 6;
    --layout-content-aside-image--reversed: column 1 / span 6;
    --layout-content-disclosure: column 1 / span 6;
    --layout-content-form: column 1 / span 6;
    --layout-content-link-list: column 1 / span 6;
    --layout-content-share: column 1 / span 6;
    --layout-content: column 1 / span 6;
    --layout-cookie-preferences: column 1 / span 6;
    --layout-extra-wide: column 1 / span 6;
    --layout-featured-campaign-content: column 1 / span 6;
    --layout-featured-campaign-image: column 1 / span 6;
    --layout-featured-card-carousel-cards: column 1 / span 5;
    --layout-featured-card-carousel-content: column 1 / span 5;
    --layout-featured: column 1 / span 6;
    --layout-footer-bar-bottom: column 1 / span 6;
    --layout-footer-bar-column: column 1 / span 6;
    --layout-footer-bar-follow: column 1 / span 6;
    --layout-footer-bar-logo: column 1 / span 6;
    --layout-full: column 1 / span var(--grid-columns);
    --layout-header-content: column 1 / span 6;
    --layout-header-image--small: column 1 / span 6;
    --layout-header-introduction: column 1 / span 6;
    --layout-header-share-buttons: column 1 / span 6;
    --layout-header-title: column 1 / span 5;
    --layout-hero-header: column 1 / span 6;
    --layout-hero-tryptich-part-left: column 1 / span 6;
    --layout-hero-tryptich-part-right: column 1 / span 6;
    --layout-menu-overlay-links: column 1 / span 6;
    --layout-notification-bar: column 1 / span 6;
    --layout-paging: column 1 / span var(--grid-columns);
    --layout-person-card-grid: column 1 / span 6;
    --layout-photo--medium: column 1 / span 6;
    --layout-photo-featured-image: column 1 / span var(--grid-columns);
    --layout-photo-featured-title: column 1 / span 5;
    --layout-photo: column 1 / span var(--grid-columns);
    --layout-quote: column 1 / span 6;
    --layout-search-bar: column 1 / span 6;
    --layout-theme-card-carousel-cards: column 2 / span 4;
    --layout-theme-card-carousel-title: column 1 / span 6;
    --layout-ultra-wide: column 1 / span 6;
    --layout-video: column 1 / span 6;
    --layout-wide: column 1 / span 6
}

@media(min-width: 530px) {
    :root {
        --layout-campaign-spread-content: column 3 / span 10;
        --layout-campaign-spread-image: column 1 / span 12;
        --layout-campaign-spread-medium-content: column 7 / span 6;
        --layout-campaign-spread-medium-image: column 1 / span 6;
        --layout-card-carousel: column 1 / span 10;
        --layout-card-grid: column 2 / span 10;
        --layout-card-list: column 2 / span 10;
        --layout-content-aside-content--default: column 1 / span 5;
        --layout-content-aside-content--reversed: column 8 / span 6;
        --layout-content-aside-image--default: column 7 / span 6;
        --layout-content-aside-image--reversed: column 1 / span 6;
        --layout-content-disclosure: column 2 / span 10;
        --layout-content-form: column 2 / span 10;
        --layout-content-link-list: column 2 / span 10;
        --layout-content-share: column 2 / span 10;
        --layout-content: column 2 / span 10;
        --layout-cookie-preferences: column 2 / span 10;
        --layout-extra-wide: column 1 / span 12;
        --layout-featured-campaign-content: column 7 / span 6;
        --layout-featured-campaign-image: column 1 / span 6;
        --layout-featured-card-carousel-cards: column 2 / span 10;
        --layout-featured-card-carousel-content: column 2 / span 8;
        --layout-featured: column 5 / span 8;
        --layout-footer-bar-bottom: column 1 / span 12;
        --layout-footer-bar-column: span 6;
        --layout-footer-bar-follow: span 6;
        --layout-footer-bar-logo: column 1 / span 12;
        --layout-header-content: column 2 / span 10;
        --layout-header-image--small: column 1 / span 12;
        --layout-header-introduction: column 2 / span 10;
        --layout-header-share-buttons: column 2 / span 10;
        --layout-header-title: column 2 / span 8;
        --layout-hero-header: column 2 / span 7;
        --layout-hero-tryptich-part-left: column 1 / span 12;
        --layout-hero-tryptich-part-right: column 1 / span 12;
        --layout-menu-overlay-links: column 2 / span 10;
        --layout-notification-bar: column 1 / span 12;
        --layout-person-card-grid: column 2 / span 10;
        --layout-photo--medium: column 2 / span 10;
        --layout-photo-featured-image: column 1 / span 12;
        --layout-photo-featured-title: column 2 / span 8;
        --layout-photo: column 1 / span 12;
        --layout-quote: column 3 / span 8;
        --layout-search-bar: column 2 / span 10;
        --layout-theme-card-carousel-cards: column 2 / span 10;
        --layout-theme-card-carousel-title: column 1 / span 12;
        --layout-ultra-wide: column 1 / span 12;
        --layout-video: column 2 / span 10;
        --layout-wide: column 1 / span 12
    }
}

@media(min-width: 800px) {
    :root {
        --layout-campaign-spread-content: column 9 / span 8;
        --layout-campaign-spread-image: column 1 / span 8;
        --layout-campaign-spread-medium-content: column 9 / span 7;
        --layout-campaign-spread-medium-image: column 2 / span 7;
        --layout-card-carousel: column 1 / span 16;
        --layout-card-grid: column 3 / span 12;
        --layout-card-list: column 4 / span 10;
        --layout-content-aside-content--default: column 1 / span 7;
        --layout-content-aside-content--reversed: column 10 / span 7;
        --layout-content-aside-image--default: column 9 / span 8;
        --layout-content-aside-image--reversed: column 1 / span 8;
        --layout-content-disclosure: column 4 / span 10;
        --layout-content-form: column 4 / span 10;
        --layout-content-link-list: column 4 / span 10;
        --layout-content-share: column 4 / span 10;
        --layout-content: column 4 / span 10;
        --layout-cookie-preferences: column 4 / span 10;
        --layout-extra-wide: column 3 / span 12;
        --layout-featured-campaign-content: column 9 / span 8;
        --layout-featured-campaign-image: column 1 / span 8;
        --layout-featured-card-carousel-cards: column 7 / span 10;
        --layout-featured-card-carousel-content: column 1 / span 6;
        --layout-featured: column 9 / span 8;
        --layout-footer-bar-bottom: column 1 / span 16;
        --layout-footer-bar-column: span 4;
        --layout-footer-bar-follow: column 13 / span 4;
        --layout-footer-bar-logo: column 1 / span 16;
        --layout-header-content: column 4 / span 10;
        --layout-header-image--small: column 3 / span 12;
        --layout-header-introduction: column 4 / span 10;
        --layout-header-share-buttons: column 12 / span 5;
        --layout-header-title: column 4 / span 8;
        --layout-hero-header: column 2 / span 9;
        --layout-hero-tryptich-part-left: column 1 / span 8;
        --layout-hero-tryptich-part-right: column 9 / span 8;
        --layout-menu-overlay-links: column 4 / span 10;
        --layout-notification-bar: column 2 / span 14;
        --layout-person-card-grid: column 3 / span 12;
        --layout-photo--medium: column 4 / span 10;
        --layout-photo-featured-image: column 1 / span 16;
        --layout-photo-featured-title: column 4 / span 8;
        --layout-photo: column 3 / span 12;
        --layout-quote: column 2 / span 14;
        --layout-search-bar: column 4 / span 10;
        --layout-theme-card-carousel-cards: column 2 / span 14;
        --layout-theme-card-carousel-title: column 1 / span 16;
        --layout-ultra-wide: column 2 / span 14;
        --layout-video: column 4 / span 10;
        --layout-wide: column 3 / span 12
    }
}

@media(min-width: 1180px) {
    :root {
        --layout-campaign-spread-content: column 10 / span 9;
        --layout-campaign-spread-image: column 1 / span 9;
        --layout-campaign-spread-medium-content: column 10 / span 8;
        --layout-campaign-spread-medium-image: column 2 / span 8;
        --layout-card-carousel: column 2 / span 16;
        --layout-card-grid: column 2 / span 16;
        --layout-card-list: column 5 / span 10;
        --layout-content-aside-content--default: column 2 / span 7;
        --layout-content-aside-content--reversed: column 11 / span 7;
        --layout-content-aside-image--default: column 10 / span 8;
        --layout-content-aside-image--reversed: column 2 / span 8;
        --layout-content-disclosure: column 5 / span 10;
        --layout-content-form: column 5 / span 10;
        --layout-content-link-list: column 5 / span 10;
        --layout-content-share: column 5 / span 10;
        --layout-content: column 5 / span 10;
        --layout-cookie-preferences: column 5 / span 10;
        --layout-extra-wide: column 4 / span 12;
        --layout-featured-campaign-content: column 11 / span 7;
        --layout-featured-campaign-image: column 1 / span 8;
        --layout-featured-card-carousel-cards: column 10 / span 9;
        --layout-featured-card-carousel-content: column 1 / span 8;
        --layout-featured: column 10 / span 9;
        --layout-footer-bar-bottom: column 1 / span 18;
        --layout-footer-bar-column: span 4;
        --layout-footer-bar-follow: column 15 / span 4;
        --layout-footer-bar-logo: column 1 / span 18;
        --layout-header-content: column 5 / span 10;
        --layout-header-image--small: column 4 / span 12;
        --layout-header-introduction: column 5 / span 10;
        --layout-header-share-buttons: column 13 / span 5;
        --layout-header-title: column 5 / span 8;
        --layout-hero-header: column 2 / span 8;
        --layout-hero-tryptich-part-left: column 1 / span 9;
        --layout-hero-tryptich-part-right: column 10 / span 9;
        --layout-menu-overlay-links: column 5 / span 10;
        --layout-notification-bar: column 2 / span 16;
        --layout-person-card-grid: column 2 / span 16;
        --layout-photo--medium: column 5 / span 10;
        --layout-photo-featured-image: column 2 / span 16;
        --layout-photo-featured-title: column 5 / span 8;
        --layout-photo: column 4 / span 12;
        --layout-quote: column 3 / span 14;
        --layout-search-bar: column 5 / span 10;
        --layout-theme-card-carousel-cards: column 3 / span 14;
        --layout-theme-card-carousel-title: column 1 / span 18;
        --layout-ultra-wide: column 2 / span 16;
        --layout-video: column 5 / span 10;
        --layout-wide: column 4 / span 12
    }
}

@media(min-width: 1580px) {
    :root {
        --layout-campaign-spread-content: column 11 / span 10;
        --layout-campaign-spread-image: column 1 / span 10;
        --layout-campaign-spread-medium-content: column 11 / span 8;
        --layout-campaign-spread-medium-image: column 3 / span 8;
        --layout-card-carousel: column 4 / span 14;
        --layout-card-grid: column 4 / span 14;
        --layout-card-list: column 7 / span 8;
        --layout-content-aside-content--default: column 5 / span 5;
        --layout-content-aside-content--reversed: column 12 / span 5;
        --layout-content-aside-image--default: column 11 / span 7;
        --layout-content-aside-image--reversed: column 4 / span 7;
        --layout-content-disclosure: column 7 / span 8;
        --layout-content-form: column 7 / span 8;
        --layout-content-link-list: column 7 / span 8;
        --layout-content-share: column 7 / span 8;
        --layout-content: column 7 / span 8;
        --layout-cookie-preferences: column 7 / span 8;
        --layout-extra-wide: column 5 / span 12;
        --layout-featured-campaign-content: column 12 / span 8;
        --layout-featured-campaign-image: column 2 / span 8;
        --layout-featured-card-carousel-cards: column 11 / span 7;
        --layout-featured-card-carousel-content: column 4 / span 6;
        --layout-featured: column 11 / span 8;
        --layout-footer-bar-bottom: column 1 / span 20;
        --layout-footer-bar-column: span 5;
        --layout-footer-bar-follow: column 16 / span 5;
        --layout-footer-bar-logo: column 1 / span 20;
        --layout-header-content: column 7 / span 8;
        --layout-header-image--small: column 6 / span 10;
        --layout-header-introduction: column 7 / span 8;
        --layout-header-share-buttons: column 15 / span 4;
        --layout-header-title: column 7 / span 6;
        --layout-hero-header: column 3 / span 7;
        --layout-hero-tryptich-part-left: column 1 / span 10;
        --layout-hero-tryptich-part-right: column 11 / span 10;
        --layout-menu-overlay-links: column 7 / span 8;
        --layout-notification-bar: column 4 / span 14;
        --layout-person-card-grid: column 4 / span 14;
        --layout-photo--medium: column 7 / span 8;
        --layout-photo-featured-image: column 4 / span 14;
        --layout-photo-featured-title: column 7 / span 6;
        --layout-photo: column 5 / span 12;
        --layout-quote: column 5 / span 12;
        --layout-search-bar: column 7 / span 8;
        --layout-theme-card-carousel-cards: column 5 / span 12;
        --layout-theme-card-carousel-title: column 1 / span 20;
        --layout-ultra-wide: column 2 / span 18;
        --layout-video: column 7 / span 8;
        --layout-wide: column 6 / span 10
    }
}

@media(min-width: 2240px) {
    :root {
        --layout-campaign-spread-content: column 13 / span 10;
        --layout-campaign-spread-image: column 1 / span 12;
        --layout-campaign-spread-medium-content: column 13 / span 8;
        --layout-campaign-spread-medium-image: column 5 / span 8;
        --layout-card-carousel: column 7 / span 12;
        --layout-card-grid: column 7 / span 12;
        --layout-card-list: column 9 / span 8;
        --layout-content-aside-content--default: column 8 / span 4;
        --layout-content-aside-content--reversed: column 14 / span 4;
        --layout-content-aside-image--default: column 13 / span 6;
        --layout-content-aside-image--reversed: column 7 / span 6;
        --layout-content-disclosure: column 9 / span 8;
        --layout-content-form: column 9 / span 8;
        --layout-content-link-list: column 9 / span 8;
        --layout-content-share: column 9 / span 8;
        --layout-content: column 9 / span 8;
        --layout-cookie-preferences: column 9 / span 8;
        --layout-extra-wide: column 5 / span 16;
        --layout-featured-campaign-content: column 14 / span 8;
        --layout-featured-campaign-image: column 4 / span 8;
        --layout-featured-card-carousel-cards: column 13 / span 7;
        --layout-featured-card-carousel-content: column 5 / span 7;
        --layout-featured: column 13 / span 9;
        --layout-footer-bar-bottom: column 1 / span 24;
        --layout-footer-bar-column: span 6;
        --layout-footer-bar-follow: column 19 / span 6;
        --layout-footer-bar-logo: column 1 / span 24;
        --layout-header-content: column 9 / span 8;
        --layout-header-image--small: column 8 / span 10;
        --layout-header-introduction: column 9 / span 8;
        --layout-header-share-buttons: column 16 / span 4;
        --layout-header-title: column 9 / span 6;
        --layout-hero-header: column 3 / span 6;
        --layout-hero-tryptich-part-left: column 1 / span 12;
        --layout-hero-tryptich-part-right: column 13 / span 12;
        --layout-menu-overlay-links: column 9 / span 8;
        --layout-notification-bar: column 6 / span 14;
        --layout-person-card-grid: column 7 / span 12;
        --layout-photo--medium: column 9 / span 8;
        --layout-photo-featured-image: column 6 / span 14;
        --layout-photo-featured-title: column 9 / span 6;
        --layout-photo: column 7 / span 12;
        --layout-quote: column 7 / span 12;
        --layout-search-bar: column 9 / span 8;
        --layout-theme-card-carousel-cards: column 7 / span 12;
        --layout-theme-card-carousel-title: column 1 / span 24;
        --layout-ultra-wide: column 2 / span 22;
        --layout-video: column 9 / span 8;
        --layout-wide: column 8 / span 10
    }
}
