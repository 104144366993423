@import 'styles/groenlinks.css';
@import 'styles/overwrites.css';

html, body {
    background-color: #38a935;
}

.background {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80vh;
    background-image: url(styles/images/earth.jpg);
    background-size: cover;
}

.app-logo {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.share-button {
    color: #533bff;
    border-color: #533bff;
}
.share-button:active,
.share-button:focus,
.share-button:hover {
    color: #1f1661;
    border-color: #1f1661;

}

:root {
    --cell-width: 6rem;
    --grid-gap: 5px;
    --grid-width: calc(4 * (var(--cell-width) + var(--grid-gap)) + var(--grid-gap));
}

.menu-bar {
    margin-bottom: 30px;
}

@media(max-width: 420px) {
    :root {
        --cell-width: 5rem;
    }

    .menu-bar {
        margin-bottom: 10px;
    }
}

@media(max-width: 360px) {
    :root {
        --cell-width: 4.5rem;
    }
}

.Bingo-wrapper {
    width: var(--grid-width);
    margin: 0 auto 5em;
}

.Bingo-table {
    font-family: "Helvetica Neue LT Std Webfont", "Suisse Webfont", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: grid;
    grid-template-columns: repeat(4, var(--cell-width));
    grid-gap: var(--grid-gap);
    align-content: center;
    padding: var(--grid-gap);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    position: relative;
}

.Bingo-cell {
    background-color: rgba(255, 255, 255, 0.85);
    height: var(--cell-width);
    text-align: center;
    color: #533bff;
    padding: 4px;
    display: block;
    position: relative;
}

.Bingo-cell-mark.marked {
    background-image: url(styles/images/marked.png);
}

.Bingo-cell-mark {
    background-size: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    bottom: 0;
}

.Bingo-cell.ready {
    display: flex;
}

.Bingo-label {
    width: calc(var(--cell-width) - 8px);
    margin: auto;
    position: relative;
}

.Bingo-label div {
    line-height: 1.2em;
}

.Marked {
    background: #dd2132a1;
    pointer-events: none;
    position: absolute;
}

.MarkedRow {
    height: .5em;
    width: 90%;
    left: 5%;
}

.MarkedColumn {
    width: .5em;
    top: 5%;
    height: 90%;
}
.MarkedDiagonal {
    width: 100%;
    height: .5em;
}

.richtext--regular h3.share-h3 {
    color: rgb(83, 59, 255);
    background: white;
    font-size: 22px;
    line-height: 24px;
    float: right;
    padding: 8px 5px 0px;
    display: inline-block;
    margin: 5px 5px 0px;
}

.Fitter {
    width: 88px;
    height: 88px;
}

@media(max-width: 420px) {
    .Fitter {
        width: 72px;
        height: 72px;
    }
}

@media(max-width: 360px) {
    .Fitter {
        width: 64px;
        height: 64px;
    }
}

.ready .Fitter {
    width: unset;
    height: unset;
}

@media(min-width: 420px) {
    .richtext--regular h3.share-h3 {
        font-size: 28px;
        line-height: 30px;
    }
}

@media(min-width: 800px) {
    .richtext--regular h3.share-h3 {
        font-size: 30px;
        line-height: 32px;
    }
}

@media (max-width: 529px) {
    .share-button {
        font-size: 16px;
        height: 40px;
        line-height: 25px;
        padding: 8px;
        width: 40px;
    }

    .share-button .share-button__icon {
        height: 19px;
        width: 19px;
    }
}
.bingo-header {
    margin-top: 1em;
    margin-bottom: 1em;
    color: white;
}
@media (max-width: 529px) {
    .bingo-header {
        font-size: 30px;
    }
}
