@font-face {
    font-family: 'Helvetica Neue LT Std Webfont';
    src: url('fonts/HelveticaNeueLTStd-Blk.woff2') format('woff2'), url('fonts/HelveticaNeueLTStd-Blk.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 100;
    src: url('fonts/SuisseIntl-Ultralight-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Ultralight-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 100;
    src: url('fonts/SuisseIntl-UltralightItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-UltralightItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 200;
    src: url('fonts/SuisseIntl-Thin-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Thin-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 200;
    src: url('fonts/SuisseIntl-ThinItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-ThinItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/SuisseIntl-Light-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Light-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 300;
    src: url('fonts/SuisseIntl-LightItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-LightItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/SuisseIntl-Regular-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Regular-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/SuisseIntl-RegularItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-RegularItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 450;
    src: url('fonts/SuisseIntl-Book-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Book-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 450;
    src: url('fonts/SuisseIntl-BookItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-BookItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 500;
    src: url('fonts/SuisseIntl-Medium-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Medium-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 500;
    src: url('fonts/SuisseIntl-MediumItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-MediumItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/SuisseIntl-SemiBold-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-SemiBold-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 600;
    src: url('fonts/SuisseIntl-SemiBoldItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-SemiBoldItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/SuisseIntl-Bold-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Bold-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 700;
    src: url('fonts/SuisseIntl-BoldItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-BoldItalic-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/SuisseIntl-Black-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-Black-WebS.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'Suisse Webfont';
    font-style: italic;
    font-weight: 900;
    src: url('fonts/SuisseIntl-BlackItalic-WebS.woff2') format('woff2'), url('fonts/SuisseIntl-BlackItalic-WebS.woff') format('woff');
}
