.swiper-contaner .flickity-viewport {
    overflow: visible;
}

.card-slider {
    margin-right: 15px;
    width: 100%;
}

@media(min-width: 530px) {
    .card-slider {
        width: 50%;
    }
}

@media(min-width: 800px) {
    .card-slider {
        width: 33%;
    }
}

@media(min-width: 1180px) {
    .card-slider {
        width: 25%;
    }
}

.form-container h3 {
    font: var(--heading-3-font-style) var(--heading-3-font-variant) var(--heading-3-font-weight) var(--heading-3-font-size)/var(--heading-3-line-height) var(--heading-3-font-stack);
    letter-spacing: var(--heading-3-letter-spacing);
    text-transform: var(--heading-3-text-transform);
    word-spacing: var(--heading-3-word-spacing);
    padding-bottom: var(--heading-3-padding-bottom);
    padding-top: var(--heading-3-padding-top);
}
